<i18n src='../../../../locales.json'></i18n>

<template>
  <v-expansion-panel
    class="payment-details pri-center-expansion-panel"
    expand
    role="presentation"
    v-model="openPanel"
  >
    <v-expansion-panel-content
      role="presentation"
      tabindex="-1"
    >
      <div
        ref="policydetails"
        slot="header"
        id="policy_overview_details"
        role="button"
        tabindex="0"
        :aria-expanded="`${openPanel[0]}`"
      >
        {{ __t('L_policy_view_policy_details') }}
      </div>

      <v-flex
        xs12
        class="grey lighten-4 coverage-details"
      >
        <v-tabs
          id="coverage_policy_tabs"
          v-model="tabs"
          grow
          class="pri-tabs mb-3 policy-tab"
        >
          <v-tab
            ripple
            class="text-capitalize no-border"
          >
            <img
              :src="require('./assets/coverage-details-icon.png')"
              class="mr-2 hidden-xs-only"
              alt="Policy Details"
            >
            <h3 class="inherit cobalt">
              {{ __t('L_policy_coverage_details') }}
            </h3>
          </v-tab>
          <v-tab
            ripple
            class="text-capitalize"
          >
            <img
              :src="require('./assets/disabled-policy-doc-icon.png')"
              class="mr-2 hidden-xs-only"
              alt="Policy Documents"
            >
            <h3 class="inherit cobalt">
              <a @click.stop="navigateToDocumentsPage">{{ $t('L_policy_policy_documents') }}</a>
            </h3>
          </v-tab>
          <v-tab-item>
            <ResponsiveTable
              :headers="formattedHeaders"
              :data="formattedCoverageData"
              :transform-headers="headerTransformer"
              @clicked-item="onClickItem"
              :mobile="mobile"
            />
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex';
import Ada from '@/shared/ada/ada';
import { currency, formatDate } from '@/shared/util/formatters';
import ResponsiveTable from '@/components/ResponsiveTable/ResponsiveTable.vue';
import stringValues from '@/shared/util/stringValues';
import __t from '../../../../translate';
import USForms from './components/USForms/USForms.vue';
import NYForms from './components/NYForms/NYForms.vue';
import CAForms from './components/CAForms/CAForms.vue';

export function policyDetailsReducer(phase) {
  return (result, key) => {
    let text = phase[key];
    let clickable = false;
    let itemClass = '';
    if (key === 'benefitAmount') {
      text = currency(Math.round(+phase.coverageAmount), 0);
      itemClass = 'text-md-right';
    }
    if (key === 'issueDate') {
      text = formatDate(phase.issueDate);
    }
    if (key === 'dateOfBirth') {
      text = formatDate(phase.dateOfBirth);
    }
    if (key === 'planDescription' && phase?.planText?.length > 0) {
      clickable = true;
    }
    if (key === 'underwritingClass') {
      text = this.$t(stringValues.underwritingClass[phase.underwritingClass]);
    }
    result[key] = {
      text,
      clickable,
      itemClass,
    };
    return result;
  };
}

export function phaseMap(phase) {
  const transformKeys = [
    'insuredName',
    'dateOfBirth',
    'issueAge',
    'planType',
    'underwritingClass',
    'issueDate',
    'benefitAmount',
    'planDescription',
    'planText',
    'sex',
  ];
  return transformKeys.reduce(policyDetailsReducer.bind(this)(phase), {});
}

export default {
  name: 'PolicyDetailsPanel',
  components: {
    ResponsiveTable,
    USForms,
    NYForms,
    CAForms,
  },
  data: () => ({
    mobile: false,
    headers: [
      {
        text: 'L_policy_insured',
        value: 'insuredName',
      },
      {
        text: 'L_policy_dob',
        value: 'dateOfBirth',
      },
      {
        text: 'L_policy_sex',
        value: 'sex',
      },
      {
        text: 'L_policy_age',
        value: 'issueAge',
      },
      {
        text: 'L_policy_plan_type',
        value: 'planDescription',
      },
      {
        text: 'L_policy_underwriting_class',
        value: 'underwritingClass',
      },
      {
        text: 'L_policy_issue_date',
        value: 'issueDate',
      },
      {
        text: 'L_policy_benefit_amount',
        value: 'benefitAmount',
      },
    ],
    openPanel: [],
    tabs: 0,
  }),
  props: {
    policyData: {
      type: Object,
      required: true,
    },
    policyDocuments: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  computed: {
    ...mapGetters('policy', {
      policy: 'currentPolicy',
      step: 'step',
    }),
    isUsPolicy() {
      return this?.policy?.isUSPolicy;
    },
    isNyPolicy() {
      return this?.policy?.isNewYorkPolicy;
    },
    isCanadaPolicy() {
      return this?.policy?.isCanadaPolicy;
    },
    headerTransformer() {
      const transformer = (text) => {
        const translateHeaders = {
          L_policy_plan_type: true,
          L_policy_underwriting_class: true,
          L_policy_benefit_amount: true,
        };
        return (translateHeaders[text]) ? __t.bind(this)(text) : this.$t(text);
      };
      return transformer.bind(this);
    },
    formattedCoverageData() {
      try {
        return this.policyData.phases.filter(ph => ph.phaseIsAnnuity === false || ph.phaseIsAnnuity === 'false').map(phaseMap.bind(this));
      } catch (error) {
        return [];
      }
    },
    formattedHeaders() {
      const headersMapper = (value) => {
        value.sortable = false;
        value.class = `pl-2 column-header${this.policyData.pending ? '' : ' pt-2'}`;
        return value;
      };
      return this.headers.map(headersMapper.bind(this));
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    checkScreenSize() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    },
    onClickItem(row) {
      this.dialogProps.dialogOpen = true;
      this.dialogProps.content = row.planText.text ? row.planText.text.replace(/#\$#/g, '<br>').replace(/\*/g, '&#8226;') : '';
      this.dialogProps.title = row.planDescription.text ? row.planDescription.text : '';
      this.dialogProps.button = this.$t('L_close');
      this.dialogProps.scrollToContinue = this.$t('L_scroll_to_continue');
    },
    __t,
    adaActions(tabs) {
      Ada.addKeyClick(this.$refs.policydetails);
      Ada.makeTabs(tabs);
    },
    navigateToDocumentsPage() {
      try {
        const i = this.$dynatrace.enterAction('link_policyDocuments_policyDetailsPage');
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
      if (this.isMobile) {
        this.$router.push({ name: 'DocumentListMobile', params: { routeName: 'PolicyDocuments' } });
      } else {
        this.$router.push({ name: 'PolicyDocuments' }); // default
      }
    },
  },
  watch: {
    openPanel() {
      // Have to do this because vuetify tabs will not render
      // the slider as selected while the expansion panel is closed
      const slider = this.$el.querySelector('.v-tabs__slider-wrapper');
      if (slider) {
        const numTabs = this.policyData.pending ? 1 : 2;
        const sliderWidth = `${100 / numTabs}%`;
        // Only update if width is not set or the width is 0px
        if (!slider.style.width || slider.style.width === '0px') {
          slider.style.width = sliderWidth;
        }
      }
    },
  },
  mounted() {
    const tabs = document.getElementById('coverage_policy_tabs');
    this.adaActions(tabs);
    this.checkScreenSize();
  },
  created() {
    window.addEventListener('resize', this.checkScreenSize);
  },
  destroyed() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
};
</script>

<style lang="stylus" scoped>
  @require '~vuetify/src/stylus/settings/_variables'
  .cobalt{
    color: #02579E;
  }
  // Margin based on breakpoint
  .total-coverage
    @media $display-breakpoints.xs-only
      margin-top: $spacers.four.y !important
  .no-border
    border-bottom: none;

  .total-coverage-footer
    padding-top: $spacers.three.y;
    padding-left: $spacers.three.x;
    padding-bottom: $spacers.five.y;
    margin-bottom: $spacers.zero.y;
    @media $display-breakpoints.sm-and-up
      font-size: 18px;
      padding-bottom: $spacers.two.y;
    .total-coverage-title
      font-weight: $font-weights.medium;
      @media $display-breakpoints.sm-and-up
        margin-right: $spacers.two.x;
        padding-left: 38px;
    .total-coverage-value
      @media $display-breakpoints.sm-and-up
        font-weight: $font-weights.regular;
        margin-right: $spacers.two.x;

  .coverage-details
    @media $display-breakpoints.sm-and-up
      padding-left: $spacers.three.x;
      padding-right: $spacers.three.x;
      padding-top: $spacers.three.y;
      padding-bottom: $spacers.three.y;
    @media $display-breakpoints.md-and-up
      padding-left: $spacers.four.x;
      padding-right: $spacers.four.x;
</style>
